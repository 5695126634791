export default {
    name: "ExpiredWarrant",
    created () {
        this.$nextTick(function () {
            this.GetDeployedExpireWarrant()
        });
    },
    mounted () {

    },
    data () {
        return {
            ExpiredData: []
        };
    },
    props: ['JudgeIos'],
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取 Expired Warrant ric 数据
        GetDeployedExpireWarrant: function () {
            let ricArr = []
            $.ajax({
                type: "post",
                url: this.$$.mibb + "GetDeployedExpireWarrant",
                data: { token: "webkey" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        $.each(data.text, (i, c) => {
                            ricArr.push(c.ric)
                        })
                        this.GetMarketData(ricArr)
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取 Expired Warrant 详细数据
        GetMarketData: function (ric) {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetMarketData",
                data: { token: "webkey", ric: ric.toString(), type: "warrantdata" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        let num = 0
                        $.each(ric, (i, c) => {
                            this.ExpiredData.push(data[c])
                        })
                    }

                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
    }
};